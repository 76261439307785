//セッションストレージでフリークエンシーキャップ
//GAMや広告枠の挙動で使う
//呼び出しコード
// 広告ID、最大表示頻度、インターバル時間（分）、表示確率を設定
//var adId = 'group1';
//var maxFrequency = 3;
//var intervalMinutes = 10;
//var displayProbability = 0.5;

// 結果を取得
//var canDisplayAd = window.wikiFq.checkWikifqDisplay(adId, maxFrequency, intervalMinutes, displayProbability);
//console.log("Ad Display Result:", canDisplayAd); // true または false が表示される


// オブジェクトを生成する関数
export function createWikiFq() {

    // ページビュー中の処理済み広告IDと結果を管理するマップ
    const processedAdsResults = new Map();

    // 広告を表示するか判断する関数
    function canDisplayAd(adId, maxFrequency, intervalMinutes, displayProbability) {
        // 同じadIdが複数回実行されないようにする
        if (processedAdsResults.has(adId)) {
            return processedAdsResults.get(adId);
        }

        const wikifqData = getData(adId);
        if (!wikifqData) {
            processedAdsResults.set(adId, false);
            return false; // セッションストレージにアクセスできない場合、広告を表示しない
        }

        const currentTime = Date.now();
        const intervalMilliseconds = intervalMinutes * 60 * 1000;
        const elapsedTime = currentTime - wikifqData.intervalStart;

        if (elapsedTime > intervalMilliseconds) {
            // インターバル時間が過ぎた場合、frequencyを0にリセット
            wikifqData.frequency = 0;
            wikifqData.intervalStart = currentTime;
        }

        const canDisplay = wikifqData.frequency < maxFrequency && shouldDisplay(displayProbability);

        if (canDisplay) {
            updateData(adId, wikifqData.frequency + 1, wikifqData.intervalStart);
        } else {
            updateData(adId, wikifqData.frequency, wikifqData.intervalStart);
        }

        // 処理結果をマップに保存
        processedAdsResults.set(adId, canDisplay);

        return canDisplay;
    }

    return {
        // 外部から設定を受け取り、広告表示を判断する関数
        checkWikifqDisplay: function (adId, maxFrequency, intervalMinutes, displayProbability) {
            // noinspection UnnecessaryLocalVariableJS
            const canDisplay = canDisplayAd(adId, maxFrequency, intervalMinutes, displayProbability);
            //console.log("Can Display Ad:", canDisplay);
            return canDisplay;
        }
    }
}

////////////////////////////////////////////////////////
// エクスポートされないので、このファイル内でのみ有効

// セッションストレージのキー名
const STORAGE_KEY = 'wikifqData';

// TS ならこういう型を定義できる
// interface FqData {
//     frequency: number
//     lastShown: number
//     intervalStart: number
// }

// 広告の表示データを取得
function getData(adId) {
    try {
        const wikifqData = sessionStorage.getItem(STORAGE_KEY);
        if (wikifqData) {
            const data = JSON.parse(wikifqData);
            return data[adId] || {frequency: 0, lastShown: 0, intervalStart: 0};
        }
        return {frequency: 0, lastShown: 0, intervalStart: 0};
    } catch (error) {
        console.error('Session storage is not accessible:', error);
        return null;
    }
}

// 広告の表示データを更新
function updateData(adId, frequency, intervalStart) {
    try {
        const wikifqData = sessionStorage.getItem(STORAGE_KEY);
        const data = wikifqData ? JSON.parse(wikifqData) : {};
        data[adId] = data[adId] || {frequency: 0, lastShown: 0, intervalStart: intervalStart};
        data[adId].frequency = frequency;
        data[adId].lastShown = Date.now();
        data[adId].intervalStart = intervalStart;
        sessionStorage.setItem(STORAGE_KEY, JSON.stringify(data));
    } catch (error) {
        console.error('Session storage is not accessible:', error);
    }
}

// 指定された確率でtrueを返す
function shouldDisplay(probability) {
    return Math.random() < probability;
}
