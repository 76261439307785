//セッションストレージで訪問元を記録
//GAMや広告枠の挙動で使う

// 記録用のキー名を定義
const storageKey = 'referrerDomain'

export function recordReferrerDomain() {
    // 現在のリファラーを取得
    const referrer = document.referrer;

    // 外部サイトからの訪問かどうかをチェック
    const isExternalReferrer = referrer && !referrer.includes(window.location.hostname);

    // ドメインを抽出する関数
    function extractDomain(url) {
        const a = document.createElement('a');
        a.href = url;
        return a.hostname;
    }

    if (isExternalReferrer) {
        const referrerDomain = extractDomain(referrer);

        // セッションストレージに記録があるか確認
        const existingDomain = sessionStorage.getItem(storageKey);

        // まだ記録されていない場合のみ、セッションストレージに保存
        if (!existingDomain) {
            sessionStorage.setItem(storageKey, referrerDomain);
        }

        // 記録されているドメインを返す
        return existingDomain || referrerDomain;
    } else {
        // 記録がない場合の処理
        const existingDomain = sessionStorage.getItem(storageKey);
        return existingDomain || null;
    }
}
