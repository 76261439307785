export interface OrganicTrackingData {
    search: number,  // what cycle of organic incoming
    visit: number,   // page view from last organic incoming
    total: number,   // total page view while whole of tracking session
    action: number,   // how many action executed
}

type OrganicTrackingAction = (data: OrganicTrackingData) => boolean

interface InvokableObject {
    invoke(action: OrganicTrackingAction): InvokableObject
}

export class OrganicTracking {

    private targetReferers = [
        'https://www.google.com',
        'https://www.google.co.jp',
        'https://www.yahoo.co.jp',
    ]

    private data: OrganicTrackingData|null = null

    constructor(
        private cookieName: string,
        private lifetimeSec: number,
        extraReferer?: string[]
    ) {
        if (typeof extraReferer !== 'undefined') {
            for (const item of extraReferer) {
                this.targetReferers.push(item)
            }
        }
        this.reload()
    }

    private trim(s: string): string {
        return s.replace(/^\s+|\s+$/g, '')
    }

    private cookies() {
        var rawCookies = document.cookie
        if (!rawCookies) {
            return {}
        }
        var result = {}
        var entries = rawCookies.split(';')
        for (const item of entries) {
            var kv = item.split('=')
            if (kv.length >= 2) {
                result[this.trim(kv[0])] = this.trim(kv[1])
            }
        }
        return result
    }

    private startSession() {
        this.data = {
            search: 1,  // what cycle of organic incoming
            visit: 1,   // page view from last organic incoming
            total: 1,   // total page view while whole of tracking session
            action: 0,   // how many action executed
        }
        this.save()
    }

    private isOrganicIncomingVisit() {
        // return true;
        for (const item of this.targetReferers) {
            if (document.referrer.indexOf(item) !== -1) {
                return true
            }
        }
        return false
    }

    private reload() {
        if (typeof this.cookies()[this.cookieName] === 'undefined') {
            if (this.isOrganicIncomingVisit()) {
                this.startSession()
            } else {
                this.data = null
            }
        } else {
            try {
                var cookie = this.cookies()[this.cookieName]
                this.data = JSON.parse(cookie)
                if (typeof this.data !== 'object' ||
                    isNaN(this.data.search) || isNaN(this.data.visit) ||
                    isNaN(this.data.total) || isNaN(this.data.action)
                ) {
                    this.startSession()
                } else {
                    this.data.total++
                    if (this.isOrganicIncomingVisit()) {
                        this.data.visit = 1
                        this.data.search++
                    } else {
                        this.data.visit++
                    }
                    this.save()
                }
            } catch (e) {
                this.startSession()
            }
        }
    }

    private save() {
        var d = new Date()
        d.setTime(d.getTime() + this.lifetimeSec * 1000)
        document.cookie = this.cookieName + "=" + JSON.stringify(this.data) + "; path=/; " + "expires=" + d.toUTCString()
    }

    private isOrganicIncomingSession() {
        return this.data !== null
    }

    private invokableNullObject() {
        var nullObject = {}
        nullObject['invoke'] = function () {
            return nullObject
        }
        return nullObject as InvokableObject
    }

    public static new(cookieName, lifetimeSec, extraReferer) {

        // cookieName: required String
        // lifetimeSec: required Number
        // extraReferer: optional Array

        return new OrganicTracking(cookieName, lifetimeSec, extraReferer)
    }

    // noinspection JSUnusedGlobalSymbols
    public invoke(action: OrganicTrackingAction): InvokableObject {

        // Usage: OrganicTracking.new(...).invoke(action1).invoke(action2);
        // action1 and action2 can take count data.
        // action2 or after one cancelled if action1 returns true.
        // Returning true indicates that the final action is decided.
        // if you won't increment action count, return non positive value from final function.

        if (!this.isOrganicIncomingSession()) {
            return this.invokableNullObject()
        }

        if (action(this.data)) {
            this.data.action++
            this.save()
            return this.invokableNullObject()
        }

        return this
    }
}
